import * as React from "react";
import { graphql } from "gatsby";

import { Query } from "@graphql-types";
import { P } from "@util/standard";
import CollectionTemplate from "@shared/collectionTemplate";
import { BreadcrumbPageContext } from "@util/types";

interface Props {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const CollectionPage = (props: Props) => {
  const { data, pageContext } = props;

  const currentCollection = data.allSanityCollection.nodes.filter(
    collection => collection.main?.slug?.current == pageContext.slug
  );

  if (currentCollection[0] == null) return null;

  const { main, meta } = currentCollection[0];

  if (!main) {
    return <P color="errorRed">Error loading Page</P>;
  }

  return (
    <CollectionTemplate
      title={main.title}
      hero={main.hero}
      categoryList={main.categories}
      collectionList={data.allSanityCollection.nodes}
      selectedCollection={main.slug?.current ?? ""}
      selectedCategoryName={main?.title ?? ""}
      seo={meta}
      slug={main.slug?.current}
      pageContext={pageContext}
      iban={pageContext.iban}
    />
  );
};

export default CollectionPage;

export const query = graphql`
  query CollectionPageQuery($iban: String) {
    allSanityCollection(
      sort: { fields: order, order: ASC }
      filter: { main: { region: { iban: { eq: $iban } } } }
    ) {
      nodes {
        id
        _key
        ...sanityCollectionPreview
      }
    }
  }
`;
